import { queryHandler } from "../foundation/query.handler";
import React, { useEffect } from "react";
import { scriptHandler } from "../foundation/script.handler";
import SEO from "../components/SEO";
import { Header } from "../components/Header";
import { Navbar } from "../tailwind";

export default ({
  location,
  pageContext: {
    product: { slug, name, phone, clickId, paths, articles, footer },
  },
}) => {
  const { utm_source, zip, ...restQueryParams } = queryHandler(location);

  useEffect(() => {
    scriptHandler.mediaAlpha({ placement_id: clickId, utm_source, zip, type: 'ad_unit', ...restQueryParams });
  }, [clickId, utm_source, zip]);

  return (
    <div style={{ backgroundColor: '#F9FBFD'}}>
    <SEO title={`${name} Quotes`} />
      <Navbar phoneNumber={phone}/>
      <div className="max-w-screen-lg mx-auto py-8">
        <div id="wrapper">
            <div className="text-center mt-4">
              <h1 className="text-3xl text-indigo-500 font-semibold mb-1">Thank you - A local insurance agent will contact you shortly</h1>
              <h2 className="uppercase text-lg font-semibold text-gray-900">In the meanwhile, get instant rates from the companies below:</h2>
            </div>
          <div id="media-alpha" />
        </div>
      </div>
    </div>
  );
};
